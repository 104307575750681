// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.offers-slider-outer-wrapper').each(function() {
		var displaySlides = 1;
        if($(this).hasClass('style-default')){
            displaySlides = 1;
        } else if($(this).hasClass('style-two-images')){
            displaySlides = 2;
        } else if($(this).hasClass('style-three-images')){
            displaySlides = 3;
        }

		var autoScroll = false;
        if($(this).hasClass('auto-scroll')){
            autoScroll = true;
        }

        var scrollSpeed = 300;
        if($(this).hasClass('slow-speed')){
            scrollSpeed = 1000;
        } else if($(this).hasClass('medium-speed')){
            scrollSpeed = 700;
        } else if($(this).hasClass('fast-speed')){
            scrollSpeed = 300;
        }

		$(this).find('.row-offers-slider .flexslider .slides').each(function() {
			$(this).slick({
				appendArrows: $(this).parent().find('.slick-control-wrapper'),
				appendDots: $(this).parent().find('.slick-control-wrapper'),
				dots: true,
				arrows: false,
				autoplay: autoScroll,
				autoplaySpeed: 5000,
				cssEase: 'linear',
				speed: scrollSpeed,
				slidesToShow: displaySlides,
				responsive: [{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 1,
					}
				}]
			});		
		});	
	});
}); /* end of as page load scripts */